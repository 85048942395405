import React, { useState, useEffect, useRef } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [redactText, setRedactText] = useState('');
  const [uploadProgress, setUploadProgress] = useState([]); 
  const [alertMessage, setAlertMessage] = useState(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(null);

  const allowedPasswords = ['AppleCatBall', 'y8AOHZwAIW'];
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Check cookie for stored password
    const allCookies = document.cookie.split(';').map(c => c.trim());
    const authCookie = allCookies.find(c => c.startsWith('auth_pass='));
    if (authCookie) {
      const storedPass = authCookie.split('=')[1];
      if (allowedPasswords.includes(storedPass)) {
        setIsAuthenticated(true);
      }
    }
  }, []);

  // Increment progress for the currently processing file
  useEffect(() => {
    if (currentFileIndex === null) return;
    const currentItem = uploadProgress[currentFileIndex];
    if (!currentItem || currentItem.status !== 'processing') return;

    const interval = setInterval(() => {
      setUploadProgress(prev => prev.map((item, idx) => {
        if (idx === currentFileIndex && item.status === 'processing') {
          if (item.progress < 95) {
            return { ...item, progress: item.progress + 2 };
          }
        }
        return item;
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, [currentFileIndex, uploadProgress]);

  const handleLogin = () => {
    if (allowedPasswords.includes(passwordInput)) {
      setIsAuthenticated(true);
      // Store password in cookie
      document.cookie = `auth_pass=${passwordInput}; path=/; max-age=31536000`;
    } else {
      setAlertMessage('Incorrect password');
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const pdfFiles = files.filter(file => file.type === 'application/pdf');
    if (pdfFiles.length < files.length) {
      setAlertMessage("Some selected files were not PDF and have been ignored.");
    }
    setSelectedFiles(pdfFiles);
  };

  const handleUpload = async () => {
    let textToRedact = redactText.trim() ? redactText.trim() : "Remove Personally Identifiable Information";

    if (selectedFiles.length === 0) {
      setAlertMessage("No PDF files selected");
      return;
    }

    setAlertMessage(null);

    // Initialize progress
    setUploadProgress(selectedFiles.map(file => ({
      filename: file.name,
      status: 'pending',
      blobURL: null,
      progress: 0
    })));

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      
      // Set this file to processing
      setUploadProgress(prev => prev.map((item, idx) => {
        if (idx === i) {
          return { ...item, status: 'processing', progress: 0 };
        }
        return item;
      }));
      setCurrentFileIndex(i);

      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('redact_text', textToRedact);
        formData.append('langs', 'English');

        const response = await fetch('https://surya.beaglenetwork.com/redact_pdf_pro/', {
          method: 'POST',
          body: formData
        });

        if (response.status === 403) {
          setAlertMessage("Daily page limit (40) exceeded. Please try again tomorrow.");
          setUploadProgress(prev => prev.map((item, idx) => {
            if (idx === i) {
              return { ...item, status: 'error', progress: 100 };
            }
            return item;
          }));
          break;
        }

        if (!response.ok) {
          throw new Error(`Server error on file ${file.name}: ${response.statusText}`);
        }

        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        // Set progress to 100% and status to done
        setUploadProgress(prev => prev.map((item, idx) => {
          if (idx === i) {
            return { ...item, status: 'done', blobURL, progress: 100 };
          }
          return item;
        }));

      } catch (error) {
        console.error(error);
        setAlertMessage(`Error processing file ${file.name}: ${error.message}`);
        setUploadProgress(prev => prev.map((item, idx) => {
          if (idx === i) {
            return { ...item, status: 'error', progress: 100 };
          }
          return item;
        }));
      }
    }
  };

  const handleDownloadAll = async () => {
    const zip = new JSZip();
    for (const item of uploadProgress) {
      if (item.status === 'done' && item.blobURL) {
        const blob = await fetch(item.blobURL).then(r => r.blob());
        zip.file(item.filename, blob);
      }
    }
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'all_redacted_pdfs.zip');
  };

  const handleFilePickerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
        <div className="bg-white p-8 rounded shadow max-w-sm w-full">
          <h2 className="text-2xl font-bold mb-4">Enter Password</h2>
          {alertMessage && (
            <div className="bg-red-100 text-red-700 p-2 rounded mb-4">
              {alertMessage}
            </div>
          )}
          <input 
            type="password"
            value={passwordInput}
            onChange={e => setPasswordInput(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4"
            placeholder="Password"
          />
          <button
            onClick={handleLogin}
            className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
          >
            Login
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6 relative">
      {/* Email Support Button */}
      <div className="fixed bottom-4 right-4">
        <a 
          href="mailto:hello@ai-redact.com"
          className="bg-gray-800 text-white px-4 py-2 rounded hover:bg-gray-900 text-sm"
        >
          Email Support
        </a>
      </div>
      
      <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
        <h1 className="text-3xl font-bold mb-6 text-center">PDF Redaction Tool</h1>
        
        {alertMessage && (
          <div className="bg-red-100 text-red-700 p-3 rounded mb-4">
            {alertMessage}
          </div>
        )}

        <div className="mb-4">
          <label className="block font-semibold mb-2">Upload PDF</label>
          <div
            onClick={handleFilePickerClick}
            className="border-2 border-dashed border-gray-300 rounded-md p-6 text-center flex flex-col items-center justify-center cursor-pointer hover:border-blue-400 hover:bg-blue-50 transition"
          >
            <svg className="mb-2 text-gray-500" width="40" height="40" fill="currentColor" viewBox="0 0 24 24">
              <path d="M19 9H17V5H7V9H5L10 14L15 9ZM4 17V19H20V17H4Z" />
            </svg>
            <span className="text-blue-600 font-medium">Upload a file</span> or drag and drop
            <div className="text-gray-500 text-sm mt-1">PDF up to 10MB</div>
            <input
              type="file"
              multiple
              accept="application/pdf"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="absolute w-0 h-0 opacity-0"
            />
          </div>
          {/* Display selected files if any */}
          {selectedFiles.length > 0 && (
            <div className="mt-4 bg-gray-50 p-2 rounded border border-gray-200">
              <h3 className="font-semibold mb-2 text-sm text-gray-700">Selected Files:</h3>
              <ul className="list-disc list-inside text-sm text-gray-700">
                {selectedFiles.map((file, i) => (
                  <li key={i}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Redaction Text Area */}
        <div className="mb-4">
          <label className="block font-semibold mb-1">What to Redact:</label>
          <textarea
            value={redactText}
            onChange={e => setRedactText(e.target.value)}
            className="w-full border p-3 rounded h-24"
            placeholder="Remove Personally Identifiable Information"
          />
        </div>
        
        {/* Redact and Upload Button below the textarea */}
        <div className="flex justify-end mb-6">
          <button
            onClick={handleUpload}
            disabled={!selectedFiles.length}
            className={`px-4 py-2 rounded text-white ${!selectedFiles.length ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
          >
            Redact and Upload
          </button>
        </div>

        {uploadProgress.length > 0 && (
          <div className="mb-6">
            <h2 className="text-xl font-semibold mb-4">Progress</h2>
            <ul className="space-y-4">
              {uploadProgress.map((item, idx) => (
                <li key={idx} className="p-4 bg-gray-100 rounded">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold">{item.filename}</span>
                    {item.status === 'done' && item.blobURL && (
                      <a 
                        href={item.blobURL} 
                        download={item.filename} 
                        className="text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Download
                      </a>
                    )}
                  </div>
                  <div className="w-full bg-white border border-gray-300 rounded h-4 relative overflow-hidden">
                    {item.status !== 'error' && (
                      <div 
                        className={`h-4 ${item.status === 'error' ? 'bg-red-500' : 'bg-blue-600'} transition-all`}
                        style={{ width: `${item.progress}%` }}
                      ></div>
                    )}
                    {item.status === 'error' && (
                      <div className="absolute inset-0 flex items-center justify-center text-red-700 font-semibold">
                        Error
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {uploadProgress.some(item => item.status === 'done') && (
          <div className="text-right">
            <button
              onClick={handleDownloadAll}
              className="bg-green-700 text-white px-6 py-3 rounded hover:bg-green-800 font-semibold text-lg"
            >
              Download All as Zip
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
